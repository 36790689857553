.table {
  width: auto;
  text-indent: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.table thead {
  color: #6d6d6d;
  text-transform: uppercase;
}

.table tbody tr:hover {
  background-color: #f1f2f9;
}

.table th,
.table td {
  min-width: 220px;
  padding: 1rem;
  text-align: start;
  border-bottom: 1px solid #dbdff1;
}

.table tbody td,
.table thead th {
  padding-left: 2rem;
}

.table tbody th,
.table thead th:first-child {
  position: sticky;
  left: 0;
  z-index: 1;
  font-size: 12px;
  font-weight: 700;
  color: #6d6d6d;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  background-color: white;
}

.table tbody tr:hover th {
  background-color: #f1f2f9;
}

.nameWrapper {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 0.5em;
  align-items: baseline;
}

.nameWrapper input[type="checkbox"] {
  position: absolute;
  left: -2em;
}
