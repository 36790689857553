.login {
  grid-area: login;
  gap: 1em;
  padding: 48px;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 25%);
}

.header {
  margin: 0;
  font-size: 1.5em;
  font-weight: 700;
}

.container {
  display: grid;
  grid-template-areas: ". login .";
  grid-template-columns: 1fr 480px 1fr;
}
