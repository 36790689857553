.arrow {
  transition: transform 0.15s ease-in;
}

.arrowup {
  transform: rotate(180deg);
}

.button {
  display: inline-block;
  padding: 0.6em;
  margin: 0;
  background: none;
  border: none;
  stroke: #6c1cf6;
  appearance: none;
}

tr.expanded,
tbody tr.expanded:hover {
  background-color: white;
}

tr.details,
tbody tr.details:hover {
  background-color: white;
}

tr.details td {
  padding-bottom: 4em;
}

tbody .expanded th,
tbody .expanded td {
  border-bottom: none;
}

.emptyRowMessage {
  text-align: center;
}

.bookmarkButton {
  padding: 10px;
  background: none;
  border: none;
}

.bookmarkButton img {
  height: 1.5em;
}
