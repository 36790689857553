.form {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.form button {
  width: 254px;
  padding: 12px;
  margin: 0 auto;
  color: white;
  background: #6c1cf6;
  border: none;
  border-radius: 34px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 25%);
}

.form label {
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  font-size: 14px;
  font-weight: 600;
  color: #6d6d6d;
}

.form input {
  display: block;
  width: 100%;
  height: 56px;
  padding: 1em;
  border: 1px solid #dbdff1;
  border-radius: 4px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 25%);
}

.form input:focus-within {
  border: 3px solid rgb(108 28 246 / 30%);
  outline: none;
}
