.track {
  display: flex;
  align-items: center;
  width: 3em;
  padding: 3px;
  background: #dfe2f2;
  border-radius: 54px;
  transition: justify-content 1s ease-in;
}

.button {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;
  font-size: 1em;
  background: none;
  border: none;
}

.knob {
  position: relative;
  right: 0;
  width: 18px;
  height: 18px;
  background-color: #fff;
  border-radius: 18px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 25%);
  transition: 0.1s ease-in;
}

.trackOn .knob {
  right: -24px;
  background-color: #6c1cf6;
}
