.button {
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 3rem;
  pointer-events: none;
}

.comparison {
  max-height: 70vh;
  overflow-y: scroll;
}

.comparison > * + * {
  padding-top: 2em;
}

.button button {
  display: inline-block;
  padding: 1rem 1.5rem;
  margin: 0;
  font-size: 1em;
  font-weight: 700;
  color: #6c1cf6;
  text-decoration: none;
  pointer-events: all;
  background: #fff;
  border: 1px solid #d8dbeb;
  border-radius: 50px;
  box-shadow: 0 2px 6px rgb(0 0 0 / 15%);
  appearance: none;
}

.dialogContainer,
.dialogOverlay {
  position: fixed;
  inset: 0;
}

.dialogContainer {
  z-index: 2;
  display: flex;
}

.dialogContainer[aria-hidden="true"] {
  display: none;
}

.dialogOverlay {
  background-color: rgb(0 0 0 / 50%); /* 1 */
}

.dialogTitle {
  width: 100%;
  padding: 0 0 1em;
  margin: 0 auto;
  font-size: 40px;
  font-weight: 400;
  line-height: 48px;
  text-align: center;
}

.dialogContent {
  position: relative;
  z-index: 2;
  min-width: 90vh;
  max-width: 1440px;
  padding: 56px;
  margin: auto;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgb(0 0 0 / 25%);
}

.dialogCloseButton {
  position: absolute;
  top: 54px;
  right: 62px;
  padding: 4px;
  margin: 0;
  background: none;
  border: none;
}

.tableContainer {
  overflow-x: scroll;
}
