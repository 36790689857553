.header {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 48px;
  color: white;
}

.title {
  margin: 0;
  font-size: 32px;
  font-weight: 400;
}
