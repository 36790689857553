.table {
  width: 100%;
  text-indent: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.table thead {
  color: #6d6d6d;
  text-transform: uppercase;
}

.table tr:hover {
  background-color: rgb(219 223 241 / 40%);
}

.table th,
.table td {
  padding: 1rem;
  text-align: start;
  border-bottom: 1px solid #dbdff1;
}

.table tbody th {
  font-weight: normal;
}

.container {
  padding-top: 32px;
}

.progressContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
