.header {
  font-size: 20px;
  font-weight: 700;
}

.datalist {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2em;
}

.datum dd,
.datum dt {
  padding: 0;
  margin: 0;
}

.datum dt {
  font-size: 12px;
  font-weight: 700;
  color: #6d6d6d;
  text-transform: uppercase;
}

.datum dd {
  padding-top: 8px;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  color: #1f1f1f;
}

.loadingRow {
  background-color: white;
}

.progressContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
}
