.screener {
  min-height: 100vh;
  background-color: #f6f7fb;
}

.filterBar {
  display: flex;
  justify-content: space-between;
}

.bookmarkToggle {
  padding-top: 50px;
}

.data {
  max-width: 1440px;
  padding: 48px;
  margin: 0 auto;
}

.searchArea {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 48px 48px;
}

.searchBar {
  display: flex;
  gap: 1em;
  align-items: center;
  width: 100%;
  max-width: 718px;
  padding: 1em;
  background: #fff;
  border: none;
  border: 3px solid transparent;
  border-radius: 4px;
  outline: none;
  box-shadow: 0 1px 2px rgb(0 0 0 / 25%);
}

.searchInput {
  flex-grow: 1;
  padding: 0;
  font-weight: 600;
  border: none;
  outline: none;
}

.searchBar:focus-within {
  border-color: rgb(108 28 246 / 30%);
}

.searchInput::placeholder {
  color: #6d6d6d;
}

.logoutFooter {
  display: flex;
  justify-content: flex-end;
  padding: 10px;
  background-color: #f6f7fb;
}

.logoutFooter button {
  padding: 1em 2em;
  background: white;
  border: none;
  border-radius: 30px;
  box-shadow: 0 1px 2px rgb(0 0 0 / 25%);
}
