:root {
  --select-border: #777;
  --select-focus: blue;
  --select-arrow: var(--select-border);
}

.select {
  grid-area: select;
  width: 100%;
  height: 48px;
  padding: 0 1em 0 0;
  margin: 0;
  font-family: inherit;
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  cursor: inherit;
  background-color: transparent;
  border: none;
  outline: none;
  appearance: none;
}

.arrow {
  grid-area: select;
  justify-self: end;
  stroke: #6c1cf6;
}

.labeltext {
  position: relative;
  top: -1.65em;
  left: -0.2em;
  grid-area: select;
  font-size: 0.6em;
  font-weight: 700;
  text-transform: uppercase;
  opacity: 0;
}

.visible {
  opacity: 0.6;
}

.label {
  display: grid;
  grid-template-areas: "select";
  align-items: center;
  width: 100%;
  min-width: 20ch;
  max-width: 30ch;
  padding: 0.25em 1em;
  line-height: 1.1;
  background-color: #fff;
  background-image: linear-gradient(to top, #f9f9f9, #fff 33%);
  border: 3px solid transparent;
  border-radius: 0.25em;
  box-shadow: 0 1px 2px rgb(0 0 0 / 25%);
}

.label:focus-within {
  border: 3px solid rgb(108 28 246 / 30%);
}
