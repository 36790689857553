.filters {
  display: flex;
  gap: 16px;
  width: fit-content;
}

.labelRow {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

.label {
  margin: 0;
  font-weight: 700;
  color: #6d6d6d;
  text-transform: uppercase;
}

.item {
  display: inline-block;
}

.container {
  display: flex;
  flex-direction: column;
  width: fit-content;
}

.button {
  display: inline-block;
  padding: 0.6rem 0;
  margin: 0;
  font-size: 0.6em;
  font-weight: 700;
  color: #6c1cf6;
  text-decoration: none;
  text-transform: uppercase;
  background: none;
  border: none;
  opacity: 0;
  appearance: none;
}

.visible {
  opacity: 1;
}
