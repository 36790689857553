.stars {
  font-size: 1em;
}

.filled {
  color: #e8a700;
}

.empty {
  color: #cdcdcd;
}
