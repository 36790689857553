.button {
  background: none;
  border: none;
  stroke: #6c1cf6;
  transform: rotate(90deg);
}

.button[disabled] {
  stroke: #dbdff1;
}

.rightButton {
  transform: rotate(-90deg);
}

.paginationControls {
  display: flex;
  gap: 1em;
  align-items: center;
  justify-content: center;
  padding-top: 2em;
}
